import {
  isObject,
  isArray,
  mapKeys,
  mapValues,
  snakeCase,
  camelCase,
} from 'lodash'

const mapper = (value: string, transformCase: Function) => {
  return isObject(value) ? toAnyCase(value, transformCase) : value
}

const toAnyCase = (data: any, transformCase: Function) => {
  const _mapValues = (value: string) => mapper(value, transformCase)
  if (isArray(data)) {
    // explicitly map over the array and process each element
    return data.map((item) => mapper(item, transformCase))
  } else if (isObject(data) || data === null) {
    return mapKeys(mapValues(data, _mapValues), (_value: string, key: string) =>
      transformCase(key)
    )
  } else {
    return data
  }
}

const toSnakeCase = (data: any) => {
  return toAnyCase(data, snakeCase)
}

const toCamelCase = (data: any) => {
  return toAnyCase(data, camelCase)
}

/**
 * Formats a string value to two decimal places.
 * If the input value cannot be parsed as a number, returns an empty string.
 *
 * @param value - The string representation of the number to format.
 * @returns The formatted number as a string with two decimal places, or an empty string if the value is not a valid number.
 *
 * @example
 * formatToTwoDecimals("123.456"); // "123.46"
 * formatToTwoDecimals("abc"); // ""
 */
export const formatToTwoDecimals = (value: string): string => {
  const numericValue = parseFloat(value)
  return isNaN(numericValue) ? '' : numericValue.toFixed(2)
}

export default {
  toSnakeCase,
  toCamelCase,
  formatToTwoDecimals,
}
